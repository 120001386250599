<template>
  <va-card>
    <va-card-title>{{ $t('academy_information') }}</va-card-title>
    
    <va-card-content>
      <div style="display:flex">
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item :label="$t('menu.main')" />
          <va-breadcrumbs-item :label="$t('menu.academy')" />
          <va-breadcrumbs-item :label="$t('menu.subscribers')"/>
        </va-breadcrumbs>
        <div  class="md6">
          <va-button icon="add" color="info" v-if="!formIsOpen" @click="formIsOpen = !formIsOpen" class="mr-4">{{ $t('add') }}</va-button>
        </div>
      </div>
        
      <subscribers-form :SubscribtionFees="SubscribtionFees" :formIsOpen="formIsOpen" @closeForm="closeForm" @rowInserted="insertTableRow" @rowUpdated="upateRow"></subscribers-form>


    </va-card-content>
    
  </va-card>


  <div class="mt-3">
    <div class="flex">
      <va-card stripe stripe-color="primary">
        <va-card-content>
          <div class="">
            <div class="va-table-responsive">
              <va-inner-loading :loading="isLoading">
                <table class="va-table va-table--hoverable">
                  <thead>
                    <tr>
                      <th>{{$t('name')}}</th>
                      <th>{{$t('has_paid')}}</th>
                      <th>{{$t('payment_time')}}</th>
                      <th>{{$t('actions')}}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="subscriber in subscribers" :key="subscriber.id">
                      <td>{{subscriber.user.name}}</td>
                      <td>{{subscriber.fees ? $t('has_paid') : $t('not_paid')}}</td>
                      <td>{{subscriber.payment_time ? $d(subscriber.payment_time) : "-"}}</td>
                      <td>
                        <div style="display: flex; justify-content: space-evenly;">
                          <va-button flat color="info" v-if="!subscriber.fees" @click="confirmSubscribtion(subscriber.id)">{{$t('confirm_subscribtion')}}</va-button>
                          <!-- <va-button @click="deleteRow(subscriber.id, i)" color="danger" icon="delete" /> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </va-inner-loading>
            </div>
          </div>
        </va-card-content>
                    
      </va-card>
    </div>
  </div>
</template>

<script>
import { SUBSCRIBERS_LIST, SUBSCRIBTION_FEES } from "@/graphql/queries/academy/subscribers";
import { CONFIRM_PAYMENT } from "@/graphql/mutations/academy/subscribers";
import { request, GraphQLClient } from 'graphql-request';
import SubscribersForm from '@/components/forms/academy/SubscribersForm.vue'


export default {
    data () {
      return {
        subscribers: [],
        SubscribtionFees:null,
        formIsOpen: false,
        selectedRowToEdit: {},
        isLoading: false
      }
    },
    components: {
        SubscribersForm
    },
    methods: {
        insertTableRow(row){
        this.getSubscribers();
        this.formIsOpen = false;
        },
        upateRow(row){
        this.getSponsers();
        this.selectedRowToEdit = {}
        this.formIsOpen = false;
        },
        getSubscribers(){
                this.isLoading = true;
                request(this.$store.state.appUrl, SUBSCRIBERS_LIST).then((data) => {
                this.isLoading = false;
                console.log(data.AcademySubscribtions)
                this.subscribers = data.AcademySubscribtions;
            }).catch((err) => {
                this.isLoading = false;
                this.$swal.fire({
                text:this.$t('error_msg'),
                icon:'error',
                confirmButtonColor: '#039303',
                confirmButtonText: this.$t('ok'),
                })
            });
        },
        closeForm(){
        this.formIsOpen = false
        },
        async getFees() {
            const res = await request(this.$store.state.appUrl, SUBSCRIBTION_FEES)
            console.log(res.Academy)
            this.SubscribtionFees = res.Academy[0].sub_fees
        },
        confirmSubscribtion(id) {

          this.$swal.fire({
            title: ' Do you really want to confirm payment?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Confirm!'
          }).then( async (result) => {
            if (result.isConfirmed) {

              const client = new GraphQLClient(this.$store.state.appUrl,{})
              const variables = {
                  "id": Number(id),
                  "fees": Number(this.SubscribtionFees),
                  "payment_time": new Date(Date.now())
              }
              try{
                  const res = await client.request(CONFIRM_PAYMENT,variables)
                  this.getSubscribers()
                  console.log(res.AcademySubscribtionsUpdate)
              } catch(err) {
                  console.error(err)
              }
              this.$swal.fire(
                'Done!',
                'Your Payment Confirmation has been done successfully.',
                'success'
              )
            }
          })
            

        }
    }, mounted () {
        this.getSubscribers();
        this.getFees();
    }
}
</script>

<style>

</style>