import { gql } from "graphql-request";

export const CONFIRM_PAYMENT = gql`
mutation($id: Int, $fees: Int, $payment_time: Date) {
    AcademySubscribtionsUpdate(academy_subscribtions: {id: $id,fees: $fees, payment_time: $payment_time}) {
      fees,
      payment_time
    }
  }
`

export const ADD_SUBSCRIBER = gql`
mutation($fees: Int, $payment_time: Date,$user_id: Int) {
    AcademySubscribtionsCreate(academy_subscribtions: {user_id: $user_id, fees: $fees, payment_time: $payment_time}) {
      id,
      fees,
      payment_time
    }
  }
`