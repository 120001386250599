<template>
  <div class="mt-3" v-show="formIsOpen">
    <form @submit.prevent="addSubscriber">
      <div class="md6">
        <div style="max-width: 300px;">
          <va-select
            class="mb-4"
            label="Searchable multi select"
            :options="options"
            :text-by="(option)=> option.name"
            :value-by="(option)=> option.id"
            :track-by="(option)=> option.id"
            v-model="data.user_id"
            searchable
          />
        </div>

        <div  style="max-width: 600px; display: flex; margin-left: 2rem">
          <label for="subFees">Subscribtion Fees - {{SubscribtionFees}}$</label>            
        </div>
      </div>

      <va-divider inset />
                        
      <div class="card_footer">
        <va-button outline class="mr-4" type="submit">{{$t('save')}}</va-button>
        <va-button outline color="danger" class="mr-4" @click="closeForm">{{$t('cancel')}}</va-button>
      </div>

    </form>
  </div>
</template>

<script>
import { GraphQLClient, request } from 'graphql-request';
import { GET_USERS } from '@/graphql/queries/academy/subscribers';
import { ADD_SUBSCRIBER } from '@/graphql/mutations/academy/subscribers';

        
export default {
    props: ["formIsOpen", "SubscribtionFees"],
    data() {
        return {
            data: {
                user_id: null,
                payment_time: new Date(Date.now()),
            },
            options: []
        }
    },
    created() {
        this.getUsers();
    },
    emits: ["closeForm", "rowInserted"],
    methods:{
        async addSubscriber(){
            const client = new GraphQLClient(this.$store.state.appUrl,{})
            const variables = {
                "user_id": Number(this.data.user_id),
                "payment_time": this.data.payment_time,
                "fees": Number(this.SubscribtionFees) 
            }
            try{
                const res = await client.request(ADD_SUBSCRIBER,variables)
                this.$emit('rowInserted')
                this.data= {}
                console.log(res.AcademySubscribtionsCreate)
            } catch(err) {
                console.error(err)
            }

        },
        test(){
            console.log(this.data.user_id);
        },
        async getUsers(){
             try{
                const res = await request(this.$store.state.appUrl, GET_USERS)
                console.log(res.Users)
                this.options = res.Users
                // this.options = res.Users.map(user => user.name)
                console.log(this.options)
            }catch(err){
                this.$swal.fire({
                text:this.$t('error_msg'),
                icon:'error',
                confirmButtonColor: '#039303',
                confirmButtonText: this.$t('ok'),
                })
            }
        },
        closeForm(){
            this.$emit('closeForm');
        },
       
    }
}
</script>

<style>
.md6{
    display: flex;
    justify-content: space-evenly;
    align-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: nowrap;
}

</style>