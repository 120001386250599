import { gql } from "graphql-request";

export const SUBSCRIBERS_LIST = gql`
  query {
  	AcademySubscribtions{
      id
      user_id
      fees
      payment_time
      user{
        name
      }
    }
  }
`
export const SUBSCRIBTION_FEES = gql`
  query {
    Academy{
      sub_fees
    }
  }       
`
export const GET_USERS = gql`
  query {
    Users{
      name,
      id
    }
  }
      
`